import { useMemo } from "react";

import getLayers from "./layers/getLayer";

export function useLegends(
  layerGroup,
  subgroup,
  mapLoaded,
  layers,
  custom_layer_protos,
  layersToggle,
) {
  const legends = useMemo(() => {
    return getLayers(
      layers,
      layerGroup,
      { floodGroup: subgroup },
      custom_layer_protos,
      layersToggle,
    ).legends;
  }, [layers, layerGroup, subgroup, custom_layer_protos, layersToggle]);

  return { legends };
}
