import React, { useState, useEffect, ReactNode, useRef } from "react";
import "./hover.css";

interface ClickProps {
  children: ReactNode;
  extraClasses: string;
  timeout?: number;
}

const Hover: React.FC<ClickProps> = ({
  children,
  extraClasses = "",
  timeout = 5000,
}) => {
  const [active, setActive] = useState(false);
  const classlist = useRef("");

  useEffect(() => {
    if (active) {
      classlist.current = extraClasses;
      setTimeout(() => {
        setActive(false);
        classlist.current = "";
      }, timeout);
    }
  }, [active]);

  return (
    <div className={`${classlist.current}`} onClick={() => setActive(true)}>
      {children}
    </div>
  );
};

export default Hover;
