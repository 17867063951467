import React, { ChangeEvent, useMemo, useCallback } from "react";
import "./multi-switch.css";

type ValueType = string | number;

interface MultiSwitchOptionProps {
  title: string;
  value: ValueType;
  position: string;
  getSwitchAnimation?: (value: ValueType) => void;
  switchPosition?: string;
}

export const MultiSwitchOption: React.FC<MultiSwitchOptionProps> = ({
  title,
  position,
  switchPosition,
  getSwitchAnimation,
}) => (
  <>
    <input
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        getSwitchAnimation?.(e.target.value)
      }
      name="map-switch"
      id={position}
      type="radio"
      value={position}
      checked={position === switchPosition}
    />
    <label
      className={`${position}-label ${
        switchPosition === position && "black-font"
      }`}
      htmlFor={position}
    >
      <h6>{title}</h6>
    </label>
  </>
);

interface MultiSwitchProps {
  selection: ValueType;
  onChange: (value: ValueType) => void;
  styles?: React.CSSProperties;
  children?: React.ReactElement<MultiSwitchOptionProps>[];
  theme?: "light" | "dark";
}

export const MultiSwitch: React.FC<MultiSwitchProps> = ({
  selection,
  onChange,
  styles,
  children,
  theme = "dark",
}) => {
  // Treat children as an array of MultiSwitchOption components and validate them
  const options = useMemo(
    () =>
      React.Children.toArray(children).filter(
        (child): child is React.ReactElement<MultiSwitchOptionProps> =>
          React.isValidElement(child) &&
          (child.type === MultiSwitchOption ||
            (child.type as any).displayName === MultiSwitchOption.displayName),
      ),
    [children],
  );

  const getSwitchAnimation = useCallback(
    (value: ValueType) => {
      const valueOfSelection = options.find(
        (option) => option.props.position === value,
      )?.props.value;
      onChange(valueOfSelection as ValueType);
    },
    [options, onChange],
  );

  const newPosition = useMemo(() => {
    return options.find((option) => option.props.value === selection)?.props
      .position;
  }, [selection, options]);

  return (
    <div
      className={`${
        options.length === 3 ? "triple" : "double"
      } switch-container ${theme}`}
      style={styles}
    >
      <div className={`switch ${newPosition}-position`} />
      {/* Render the option children and pass the getSwitchAnimation function */}
      {options.map((option) =>
        React.cloneElement(option, {
          getSwitchAnimation,
          switchPosition: newPosition as string,
        }),
      )}
    </div>
  );
};
