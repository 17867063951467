import React from "react";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import SelectedFeaturesMetricsPanel from "./selected-features-metrics-panel";
import SelectedFeaturesVerraPanel from "./selected-features-verra-panel";
import { LayerName } from "types/dataModel";

function SelectedFeaturesPanel({
  selectedFeatures,
  selectedYear,
  mode,
  layersToggle,
  toggleLayer,
}: {
  selectedFeatures: MapboxGeoJSONFeature[];
  selectedYear: number;
  mode: "metrics" | "verra";
  layersToggle: Record<string, boolean>;
  toggleLayer: (layer: LayerName) => void;
}) {
  return (
    <div className="selected-features-panel">
      {mode === "metrics" && (
        <SelectedFeaturesMetricsPanel
          selectedFeatures={selectedFeatures}
          selectedYear={selectedYear}
        />
      )}
      {mode === "verra" && (
        <SelectedFeaturesVerraPanel
          selectedFeatures={selectedFeatures}
          layersToggle={layersToggle}
          toggleLayer={toggleLayer}
        />
      )}
    </div>
  );
}

export default SelectedFeaturesPanel;
