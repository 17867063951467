import "./compass.css";
import { useState } from "react";
import { ReactComponent as CompassSVG } from "assets/compass.svg";
import { ReactComponent as Plus } from "assets/Plus.svg";
import { ReactComponent as Minus } from "assets/Minus.svg";
import Hover from "components/hover";
import Click from "components/click";
import { BasemapMap, BasemapStyle } from "basemap_manager/BasemapManager";
import { Viewport } from "types/map";
import { Icon } from "@iconify/react";

type GenericObject = { [key: string]: any };
function reverseObject(obj: GenericObject) {
  const reversedObject: GenericObject = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      reversedObject[obj[key]] = key;
    }
  }
  return reversedObject;
}

const ReversedBasemapMap = reverseObject(BasemapMap);

function Tooltip({ text }: { text: string }) {
  return <div className="tooltips-slot">{text}</div>;
}

interface CompassProps {
  viewport: Viewport;
  theme: BasemapStyle;
  setViewport: (viewport: Viewport) => void;
  setPermalink: () => Promise<void>;
}

export default function Compass({
  viewport,
  theme,
  setViewport,
  setPermalink,
}: CompassProps) {
  const [disableHover, setDisableHover] = useState(false);
  const [tooltipText, setTooltipText] = useState("");
  const [copyText, setCopyText] = useState("Copy Location");

  function adjustViewport(adjustment: Partial<Viewport>) {
    const newViewport: Viewport = Object.assign(viewport, adjustment);
    setViewport(newViewport);
  }

  const highlightCompass = viewport.pitch !== 0 || viewport.bearing !== 0;

  const onPermalinkClick = async () => {
    // Timer logic
    try {
      await setPermalink();
      setDisableHover(true);
      setTimeout(() => {
        setTooltipText("Link copied to clipboard");
      }, 250);
      setTimeout(() => {
        setDisableHover(false);
      }, 3000);
    } catch (error) {
      setTooltipText("Error copying link to clipboard");
    }
  };
  return (
    <div className="controls-panel-container">
      <div className="controls-panel">
        <Click extraClasses={"clicked"} timeout={500}>
          <div
            className={
              "controls-icon-container " +
              (highlightCompass ? "coral" : "white")
            }
            onClick={() => {
              onPermalinkClick();
            }}
          >
            <Hover
              text="Copy Location"
              extraClasses={ReversedBasemapMap[theme]}
              action={setTooltipText}
              disable={disableHover}
            >
              <Icon icon="mdi:link-variant" className={"controls-icon"} />
            </Hover>
          </div>
        </Click>
        <div
          className={
            "controls-icon-container " + (highlightCompass ? "coral" : "white")
          }
          onClick={() => adjustViewport({ bearing: 0, pitch: 0 })}
        >
          <Hover
            text="Reorient"
            extraClasses={ReversedBasemapMap[theme]}
            action={setTooltipText}
          >
            <CompassSVG
              fill={highlightCompass ? "coral" : "white"}
              className={"controls-icon compass"}
              style={{
                transform: `
                    rotateX(${viewport.pitch}deg)
                    rotateZ(${-viewport.bearing}deg)  
                  `,
              }}
            />
          </Hover>
        </div>
        <div
          className="controls-icon-container"
          onClick={() => adjustViewport({ zoom: viewport.zoom + 1 })}
        >
          <Hover
            text="Zoom In"
            extraClasses={ReversedBasemapMap[theme]}
            action={setTooltipText}
          >
            <div className="controls-icon">
              <Plus fill="white" />
            </div>
          </Hover>
        </div>
        <div
          className="controls-icon-container"
          onClick={() => adjustViewport({ zoom: viewport.zoom - 1 })}
        >
          <Hover
            text="Zoom Out"
            extraClasses={ReversedBasemapMap[theme]}
            action={setTooltipText}
          >
            <div className="controls-icon">
              <Minus fill="white" />
            </div>
          </Hover>
        </div>
      </div>
      <Tooltip text={tooltipText} />
    </div>
  );
}
