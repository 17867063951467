import { useEffect } from "react";
import ReactGA from "react-ga4";

/**
 * This hook is used to track page views in GA.
 * It fires a pageview event to GA on mount.
 * @param title - A title of the page to ID the page in GA
 * @param mockPath - (optional) A fake path of the page for analytics
 */
export const useAnalyticsView = (title: string, mockPath?: string) => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: mockPath, title });
  }, [mockPath, title]);
};

/**
 * This hook is used to track events in GA.
 * It returns a function that can be run to fire events and pass in optional parameters.
 * @param event - A string that represents the event name
 * @returns (params?: Object) => void
 */
export const useAnalyticsHandler = (event: string) => {
  return (params?: {
    [key: string]: string | number | boolean | Array<any> | null | undefined;
  }) => {
    ReactGA.event(event, params);
  };
};
